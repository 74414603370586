import jQuery from 'jquery';

(($) => {
  /*--------------------------------
   * mobile navigation
   ---------------------------------*/
  const $body = $('body');
  const $header = $('header');
  const $burger = $('.nav-icon-toggle', $header);
  const $mobileNav = $('.mobile-nav-container', $header);
  $burger.on('click', () => {
    $body.toggleClass('open-mobile-nav');
  });
  $mobileNav.on('click', '.toggle-sub-nav', function () { // eslint-disable-line
    const $this = $(this);
    $this.toggleClass('open');
    $this.parent().next().slideToggle();
  });

  /*--------------------------------
     * mobile meta navs
     ---------------------------------*/
  const $mobileMeta = $('.mobile-meta-navs');
  $mobileMeta.on('click', '.online', function () { // eslint-disable-line
    $(this).next().slideToggle();
  });
  $mobileMeta.on('click', '.languages .current', function () { // eslint-disable-line
    $(this).next().slideToggle();
  });

  /*--------------------------------
   * search form
   ---------------------------------*/
  const $searchForm = $('.search-form form');
  const $searchInput = $('input', $searchForm);
  $searchForm.on('click', '.open-input', () => {
    $searchForm.addClass('open');
    $searchInput.prop('disabled', false);
  });
  $searchForm.on('click', '.close-input', () => {
    $searchForm.removeClass('open').trigger('reset');
    $searchInput.prop('disabled', true);
  });

  /*--------------------------------
   * form label animations
   ---------------------------------*/
  const $labelAnim = $('.label-anim');
  $labelAnim.each((i, obj) => {
    const $obj = $(obj);
    const $input = $('input, textarea', $obj);
    if ($input.val().length > 0) {
      $obj.addClass('focus');
    }
  });
  $labelAnim.on('focus', 'input, textarea', (ev) => {
    ev.delegateTarget.classList.add('focus');
  });
  $labelAnim.on('blur', 'input, textarea', (ev) => {
    if (ev.target.value.length === 0) {
      ev.delegateTarget.classList.remove('focus');
    }
  });

  $('select').on('change', function(ev) {// eslint-disable-line
    const $this = $(this);
    const $selectLabel = $this.prev();
    if ($this.val().length) {
      $selectLabel.addClass('selected');
    } else {
      $selectLabel.removeClass('selected');
    }
  });

  /*--------------------------------
   * format redactor tables
   ---------------------------------*/
  $('table .v-top').each((i, obj) => {
    $(obj).parent().attr('valign', 'top');
  });
  $('table .v-mid').each((i, obj) => {
    $(obj).parent().attr('valign', 'middle');
  });
  $('table .v-bottom').each((i, obj) => {
    $(obj).parent().attr('valign', 'bottom');
  });

  /*--------------------------------
   * slide previous content
   ---------------------------------*/
  $('.toggle-prev').on('click', function () { // eslint-disable-line
    const $this = $(this);
    $(this).prev().slideDown();
    $this.fadeOut();
  });

  /*--------------------------------
   * resize
   ---------------------------------*/
  let resizeTimer;
  $(window).on('resize', () => {
    if (resizeTimer) {
      clearTimeout(resizeTimer); // clear any previous pending timer
    }
    resizeTimer = setTimeout(() => {
      $body.removeClass('open-mobile-nav');
    }, 200);
  });
})(jQuery);
